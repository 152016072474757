/* This example requires Tailwind CSS v2.0+ */
import React from "react";
import { Link } from "gatsby";
//import AbtLogo from "~/images/Art_of_bicycle_trips_Final_logo2.png";
//import RRLogo from "~/images/RRLogo.png";
import RRLogo from "~/images/rnr-logo.png";
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, PhoneIcon, PlayIcon, XIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import Location from "~/components/location";

const activities = [
	{
		name: "Hiking",
		description:
			"Mostly of easy to moderate difficulty that are suitable with children",
		to: "/activities/hiking",
	},
	// {
	// 	name: "Cycling",
	// 	description:
	// 		"Ride from hotel-to-hotel through our immaculately planned backroads with support van always near at hand.",
	// 	to: "/activities/cycling",
	// },
	// {
	// 	name: "Multi-activity",
	// 	description:
	// 		"Immerse yourselves into a region by balancing culture with local activities.",
	// 	to: "/activities/multi-activity",
	// },
];

const more = [
	{
		name: "Bicycle Service",
		description: "Our specialists will get your wheels turning again.",
		to: "/bicycle-service-whitefield-bangalore",
		//icon: ChartBarIcon,
	},
	{
		name: "Basic Bicycle Maintenance Class",
		description: "Get more out of your bike.",
		to: "/basic-bicycle-maintenance-course",
		//icon: ChartBarIcon,
	},
	{
		name: "Bicycle Rental",
		description: "Hire top-quality road, hybrid and mountain bikes in Bangalore.",
		to: "/bicycle-rental-bangalore",
		//icon: CursorClickIcon,
	},
];

const destinations = [
	{
		name: "France",
		to: "/destinations/france",
	},
	{
		name: "India",
		to: "/destinations/india",
	},
	{
		name: "Italy",
		to: "/destinations/italy",
	},
	{
		name: "Nepal",
		to: "/destinations/nepal",
	},
	{
		name: "Vietnam",
		to: "/destinations/vietnam",
	},
	// {
	// 	name: "Cambodia",
	// 	to: "/destinations/cambodia",
	// },
	// {
	// 	name: "Thailand",
	// 	to: "/destinations/thailand",
	// },
	// {
	// 	name: "Laos",
	// 	to: "/destinations/laos",
	// },
	// {
	// 	name: "Sri Lanka",
	// 	to: "/destinations/sri-lanka",
	// },
	// {
	// 	name: "Bhutan",
	// 	to: "/destinations/bhutan",
	// },
];
const asia = [
	{
		name: "All Asia",
		to: "/destinations/asia",
	},
	{
		name: "Bhutan",
		to: "/destinations/bhutan",
	},
	{
		name: "Cambodia",
		to: "/destinations/cambodia",
	},
	{
		name: "Laos",

		to: "/destinations/laos",
	},
	{
		name: "Nepal",
		to: "/destinations/nepal",
	},
	{
		name: "Sri Lanka",
		to: "/destinations/sri-lanka",
	},
	{
		name: "Thailand",
		to: "/destinations/thailand",
	},
	{
		name: "Vietnam",
		to: "/destinations/vietnam",
	},
];
const india = [
	{
		name: "France",
		to: "/destinations/france",
	},
	{
		name: "India",
		to: "/destinations/india",
	},
	{
		name: "Italy",
		to: "/destinations/italy",
	},
	{
		name: "Nepal",
		to: "/destinations/nepal",
	},
	{
		name: "Vietnam",
		to: "/destinations/vietnam",
	},
	// {
	// 	name: "All India",
	// 	to: "/destinations/india",
	// },
	// {
	// 	name: "Goa",
	// 	to: "/destinations/goa",
	// },
	// {
	// 	name: "Himalayas",
	// 	to: "/destinations/himalayas",
	// },

	// {
	// 	name: "Kerala and South India",
	// 	to: "/destinations/kerala",
	// },
	// {
	// 	name: "Northeast",
	// 	to: "/destinations/northeast-india",
	// },
	// {
	// 	name: "Rajasthan",
	// 	to: "/destinations/rajasthan",
	// },
];
const city = [
	{
		name: "Bangalore",
		to: "/destinations/bangalore",
	},

	// {
	// 	name: "Kochi",
	// 	to: "/destinations/kochi",
	// },
	// {
	// 	name: "Udaipur",
	// 	to: "/destinations/udaipur",
	// },
];
const day = [
	{
		name: "From Bangalore",
		to: "/destinations/bangalore",
	},
	{
		name: "From Mumbai",
		to: "/destinations/mumbai",
	},
	{
		name: "From Delhi",
		to: "/destinations/delhi",
	},
	{
		name: "From Kochi",
		to: "/destinations/kochi",
	},
	{
		name: "From Udaipur",
		to: "/destinations/udaipur",
	},
];
const specialities = [
	{
		name: "Family Adventures",
		description:
			"Offers perfect bonding opportunity in the outdoors with your kids (9-12 years) and teens (13-16 years).",
		to: "/specialities/family",
		//icon: ChartBarIcon,
	},
	{
		name: "Weekend Retreats",
		description:
			"Designed for regional travellers and are perfect to revitalize in a short span of two to four days.",
		to: "/specialities/weekend-retreats",
		//icon: ChartBarIcon,
	},
	{
		name: "Day Trips",
		description:
			"Awesome activities with a variety of local experiences. Awarded as top things to do.",
		to: "/specialities/day",
		//icon: CursorClickIcon,
	},
	{
		name: "E-bikes Available",
		description:
			"Allows you to ride together and farther with a tailwind style support.",
		to: "/specialities/e-bikes-available",
		//icon: CursorClickIcon,
	},
	{
		name: "Challenging Rides",
		description: "Challenge yourself over one of our epic rides.",
		to: "/specialities/challenging-rides",
		//icon: CursorClickIcon,
	},
	{
		name: "New Trips",
		description: "Be the first one to join our recently launched trips.",
		to: "/specialities/new-trips",
		//icon: CursorClickIcon,
	},
];

const callsToAction = [
	{ name: "Watch Demo", to: "#", icon: PlayIcon },
	{ name: "Contact Sales", to: "#", icon: PhoneIcon },
];
const resources = [
	{
		name: "Our Company",
		to: "/about-us/our-company",
	},
];
const recentPosts = [
	{ id: 1, name: "Boost your conversion rate", to: "#" },
	{
		id: 2,
		name: "How to use search engine optimization to drive traffic to your site",
		to: "#",
	},
	{ id: 3, name: "Improve your customer experience", to: "#" },
];

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export default function Navbar() {
	return (
		<Popover className="relative bg-white">
			{({ open }) => (
				<>
					<div className="max-w-7xl mx-auto">
						{/* removed border-b-2 border-gray-100, border below n*/}

						<div className="flex justify-between items-center py-2 lg:justify-start lg:space-x-10">
							<div className="flex justify-start my-4 lg:w-0 lg:flex-1">
								<Link to="/">
									<span className="sr-only">Workflow</span>
									<img
										className="h-12 w-auto sm:h-14"
										src={RRLogo}
										alt="Roots & Routes logo"
									/>
								</Link>
							</div>
							<div className="-mr-2 -my-2 lg:hidden">
								<Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary">
									<span className="sr-only">Open menu</span>
									<MenuIcon className="h-6 w-6" aria-hidden="true" />
								</Popover.Button>
							</div>
							{/* <Popover.Group as="nav" className="hidden xl:flex space-x-8">
								<Popover className="relative">
									{({ open }) => (
										<>
											<Popover.Button
												className={classNames(
													open ? "text-gray-900" : "text-gray-600",
													"group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
												)}
											>
												<span>Multi-day Adventures</span>
												<ChevronDownIcon
													className={classNames(
														open ? "text-gray-600" : "text-gray-400",
														"ml-2 h-5 w-5 group-hover:text-gray-500"
													)}
													aria-hidden="true"
												/>
											</Popover.Button>

											<Transition
												show={open}
												as={Fragment}
												enter="transition ease-out duration-200"
												enterFrom="opacity-0 translate-y-1"
												enterTo="opacity-100 translate-y-0"
												leave="transition ease-in duration-150"
												leaveFrom="opacity-100 translate-y-0"
												leaveTo="opacity-0 translate-y-1"
											>
												<Popover.Panel
													static
													className="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-2xl sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
												>
													<div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
														<div className="relative grid grid-cols-2 gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
															<div className="ml-4">
																<p className="mb-4 text-base font-medium text-primary">INDIA</p>
																{india.map((item) => (
																	<Link
																		key={item.name}
																		to={item.to}
																		className="-m-3 p-3 flex items-start rounded-lg hover:bg-secondary"
																	>
																		<div className="">
																			<p className="text-sm text-gray-600 hover:text-gray-900">
																				{item.name}
																			</p>
																		</div>
																	</Link>
																))}
															</div>
															<div className="ml-4">
																<p className="mb-4 text-base font-medium text-primary">ASIA</p>
																{asia.map((item) => (
																	<Link
																		key={item.name}
																		to={item.to}
																		className="-m-3 p-3 flex items-start rounded-lg hover:bg-secondary"
																	>
																		<div className="">
																			<p className="text-sm text-gray-600 hover:text-gray-900">
																				{item.name}
																			</p>
																		</div>
																	</Link>
																))}
															</div>
														</div>
													</div>
												</Popover.Panel>
											</Transition>
										</>
									)}
								</Popover>
								<Popover className="relative">
									{({ open }) => (
										<>
											<Popover.Button
												className={classNames(
													open ? "text-gray-900" : "text-gray-600",
													"group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
												)}
											>
												<span>Day Experiences</span>
												<ChevronDownIcon
													className={classNames(
														open ? "text-gray-600" : "text-gray-400",
														"ml-2 h-5 w-5 group-hover:text-gray-500"
													)}
													aria-hidden="true"
												/>
											</Popover.Button>

											<Transition
												show={open}
												as={Fragment}
												enter="transition ease-out duration-200"
												enterFrom="opacity-0 translate-y-1"
												enterTo="opacity-100 translate-y-0"
												leave="transition ease-in duration-150"
												leaveFrom="opacity-100 translate-y-0"
												leaveTo="opacity-0 translate-y-1"
											>
												<Popover.Panel
													static
													className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-xs sm:px-0"
												>
													<div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
														<div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
															{day.map((item) => (
																<Link
																	key={item.name}
																	to={item.to}
																	className="-m-3 p-3 flex items-start rounded-lg hover:bg-secondary"
																>
																	<div className="ml-4">
																		<p className="text-sm text-gray-600 hover:text-gray-900">
																			{item.name}
																		</p>
																	</div>
																</Link>
															))}
														</div>
													</div>
												</Popover.Panel>
											</Transition>
										</>
									)}
								</Popover>
								<Popover className="relative">
									{({ open }) => (
										<>
											<Popover.Button
												className={classNames(
													open ? "text-gray-900" : "text-gray-600",
													"group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
												)}
											>
												<span>Speciality Trips</span>
												<ChevronDownIcon
													className={classNames(
														open ? "text-gray-600" : "text-gray-400",
														"ml-2 h-5 w-5 group-hover:text-gray-500"
													)}
													aria-hidden="true"
												/>
											</Popover.Button>

											<Transition
												show={open}
												as={Fragment}
												enter="transition ease-out duration-200"
												enterFrom="opacity-0 translate-y-1"
												enterTo="opacity-100 translate-y-0"
												leave="transition ease-in duration-150"
												leaveFrom="opacity-100 translate-y-0"
												leaveTo="opacity-0 translate-y-1"
											>
												<Popover.Panel
													static
													className="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
												>
													<div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
														<div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
															{specialities.map((item) => (
																<Link
																	key={item.name}
																	to={item.to}
																	className="-m-3 p-3 flex items-start rounded-lg hover:bg-secondary"
																>
																	<div className="ml-4">
																		<p className="text-base font-medium text-primary">
																			{item.name}
																		</p>
																		<p className="mt-1 text-sm text-gray-600">
																			{item.description}
																		</p>
																	</div>
																</Link>
															))}
														</div>
													</div>
												</Popover.Panel>
											</Transition>
										</>
									)}
								</Popover>
							</Popover.Group> */}
							<div className="hidden lg:flex items-center justify-end lg:flex-1 lg:w-0">
								{/* <Link
									to="/contact-us"
									className="whitespace-nowrap text-base font-medium text-primary px-4 py-2 border border-primary rounded-full shadow-sm"
								>
									Contact Us
								</Link> */}
								<a
									href="https://wa.me/919810645938"
									target="_blank"
									rel="noopener  noreferrer nofollow"
									className="bg-whatsapp font-medium py-2 px-5 sm:px-6 border border-gray-800 rounded-full text-gray-800"
								>
									WhatsApp Us
								</a>

								{/* <Link
									to="/upcoming-rides"
									className="ml-4 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-full shadow-sm text-base font-medium text-white bg-primary hover:bg-primary"
								>
									Upcoming Trips
								</Link> */}
							</div>
							{/* <div className="hidden lg:flex items-center justify-end">
								<Location />
							</div> */}
						</div>
						<Popover.Group as="nav" className="hidden lg:flex lg:pb-10 space-x-8">
							{/* <Popover className="relative">
								{({ open }) => (
									<>
										<Popover.Button
											className={classNames(
												open ? "text-gray-900" : "text-gray-600",
												"group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
											)}
										>
											<span>Weekend Retreats</span>
											<ChevronDownIcon
												className={classNames(
													open ? "text-gray-600" : "text-gray-400",
													"ml-2 h-5 w-5 group-hover:text-gray-500"
												)}
												aria-hidden="true"
											/>
										</Popover.Button>

										<Transition
											show={open}
											as={Fragment}
											enter="transition ease-out duration-200"
											enterFrom="opacity-0 translate-y-1"
											enterTo="opacity-100 translate-y-0"
											leave="transition ease-in duration-150"
											leaveFrom="opacity-100 translate-y-0"
											leaveTo="opacity-0 translate-y-1"
										>
											<Popover.Panel
												static
												className="absolute z-10 left-1/2 transform -translate-x-1/4 mt-3 px-2 w-screen max-w-xs sm:px-0"
											>
												<div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
													<div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
														{day.map((item) => (
															<Link
																key={item.name}
																to={item.to}
																className="-m-3 p-3 flex items-start rounded-lg hover:bg-secondary"
															>
																<div className="ml-4">
																	<p className="text-sm text-gray-600 hover:text-gray-900">
																		{item.name}
																	</p>
																</div>
															</Link>
														))}
													</div>
												</div>
											</Popover.Panel>
										</Transition>
									</>
								)}
							</Popover> */}
							<Popover className="relative">
								{({ open }) => (
									<>
										<Popover.Button
											className={classNames(
												open ? "text-gray-900" : "text-gray-600",
												"group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
											)}
										>
											<span>Destinations</span>
											<ChevronDownIcon
												className={classNames(
													open ? "text-gray-600" : "text-gray-400",
													"ml-2 h-5 w-5 group-hover:text-gray-500"
												)}
												aria-hidden="true"
											/>
										</Popover.Button>

										<Transition
											show={open}
											as={Fragment}
											enter="transition ease-out duration-200"
											enterFrom="opacity-0 translate-y-1"
											enterTo="opacity-100 translate-y-0"
											leave="transition ease-in duration-150"
											leaveFrom="opacity-100 translate-y-0"
											leaveTo="opacity-0 translate-y-1"
										>
											<Popover.Panel
												static
												className="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/5"
											>
												<div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
													<div className="relative grid grid-cols-1 gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
														<div className="ml-4">
															{/* <p className="mb-4 text-base font-medium text-primary">INDIA</p> */}
															{india.map((item) => (
																<Link
																	key={item.name}
																	to={item.to}
																	className="-m-3 p-3 flex items-start rounded-lg hover:bg-secondary"
																>
																	<div className="">
																		<p className="text-sm text-gray-600 hover:text-gray-900">
																			{item.name}
																		</p>
																		{/* <p className="mt-1 text-sm text-gray-500">
																			{item.description}
																		</p> */}
																	</div>
																</Link>
															))}
														</div>
														{/* <div className="ml-4">
															<p className="mb-4 text-base font-medium text-primary">ASIA</p>
															{asia.map((item) => (
																<Link
																	key={item.name}
																	to={item.to}
																	className="-m-3 p-3 flex items-start rounded-lg hover:bg-secondary"
																>
																	<div className="">
																		<p className="text-sm text-gray-600 hover:text-gray-900">
																			{item.name}
																		</p>
																	</div>
																</Link>
															))}
														</div> */}
														{/* <div className="ml-4">
															<p className="mb-4 text-base font-medium text-primary">
																DAY TRIPS
															</p>
															{city.map((item) => (
																<Link
																	key={item.name}
																	to={item.to}
																	className="-m-3 p-3 flex items-start rounded-lg hover:bg-secondary"
																>
																	<div className="">
																		<p className="text-sm text-gray-600 hover:text-gray-900">
																			{item.name}
																		</p>
																		
																	</div>
																</Link>
															))}
														</div> */}

														{/* <div className="ml-4">
																<p className="mb-4 text-base font-medium text-primary">
																	DAY TRIPS
																</p>
																{day.map((item) => (
																	<Link
																		key={item.name}
																		to={item.to}
																		className="-m-3 p-3 flex items-start rounded-lg hover:bg-secondary"
																	>
																		<div className="">
																			<p className="text-sm text-gray-600 hover:text-gray-900">
																				{item.name}
																			</p>
																			
																		</div>
																	</Link>
																))}
															</div> */}
													</div>
													{/* <div className="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
															{callsToAction.map((item) => (
																<div key={item.name} className="flow-root">
																	<Link
																		to={item.to}
																		className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100"
																	>
																		<span className="ml-3">{item.name}</span>
																	</Link>
																</div>
															))}
														</div> */}
												</div>
											</Popover.Panel>
										</Transition>
									</>
								)}
							</Popover>
							<Popover className="relative">
								{({ open }) => (
									<>
										<Popover.Button
											className={classNames(
												open ? "text-gray-900" : "text-gray-600",
												"group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
											)}
										>
											<span>Activities</span>
											<ChevronDownIcon
												className={classNames(
													open ? "text-gray-600" : "text-gray-400",
													"ml-2 h-5 w-5 group-hover:text-gray-500"
												)}
												aria-hidden="true"
											/>
										</Popover.Button>

										<Transition
											show={open}
											as={Fragment}
											enter="transition ease-out duration-200"
											enterFrom="opacity-0 translate-y-1"
											enterTo="opacity-100 translate-y-0"
											leave="transition ease-in duration-150"
											leaveFrom="opacity-100 translate-y-0"
											leaveTo="opacity-0 translate-y-1"
										>
											<Popover.Panel
												static
												className="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/5"
											>
												<div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
													<div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
														{activities.map((item) => (
															<Link
																key={item.name}
																to={item.to}
																className="-m-3 p-3 flex items-start rounded-lg hover:bg-secondary"
															>
																<div className="ml-4">
																	<p className="text-base font-medium text-primary">
																		{item.name}
																	</p>
																	<p className="mt-1 text-sm text-gray-600">
																		{item.description}
																	</p>
																</div>
															</Link>
														))}
													</div>
												</div>
											</Popover.Panel>
										</Transition>
									</>
								)}
							</Popover>
							{/* <Popover className="relative">
								{({ open }) => (
									<>
										<Popover.Button
											className={classNames(
												open ? "text-gray-900" : "text-gray-600",
												"group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
											)}
										>
											<span>Speciality Trips</span>
											<ChevronDownIcon
												className={classNames(
													open ? "text-gray-600" : "text-gray-400",
													"ml-2 h-5 w-5 group-hover:text-gray-500"
												)}
												aria-hidden="true"
											/>
										</Popover.Button>

										<Transition
											show={open}
											as={Fragment}
											enter="transition ease-out duration-200"
											enterFrom="opacity-0 translate-y-1"
											enterTo="opacity-100 translate-y-0"
											leave="transition ease-in duration-150"
											leaveFrom="opacity-100 translate-y-0"
											leaveTo="opacity-0 translate-y-1"
										>
											<Popover.Panel
												static
												className="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
											>
												<div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
													<div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
														{specialities.map((item) => (
															<Link
																key={item.name}
																to={item.to}
																className="-m-3 p-3 flex items-start rounded-lg hover:bg-secondary"
															>
																<div className="ml-4">
																	<p className="text-base font-medium text-primary">
																		{item.name}
																	</p>
																	<p className="mt-1 text-sm text-gray-600">
																		{item.description}
																	</p>
																</div>
															</Link>
														))}
													</div>
													
												</div>
											</Popover.Panel>
										</Transition>
									</>
								)}
							</Popover> */}

							{/* <Popover className="relative">
								{({ open }) => (
									<>
										<Popover.Button
											className={classNames(
												open ? "text-gray-900" : "text-gray-600",
												"group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
											)}
										>
											<span>Activities</span>
											<ChevronDownIcon
												className={classNames(
													open ? "text-gray-600" : "text-gray-400",
													"ml-2 h-5 w-5 group-hover:text-gray-500"
												)}
												aria-hidden="true"
											/>
										</Popover.Button>

										<Transition
											show={open}
											as={Fragment}
											enter="transition ease-out duration-200"
											enterFrom="opacity-0 translate-y-1"
											enterTo="opacity-100 translate-y-0"
											leave="transition ease-in duration-150"
											leaveFrom="opacity-100 translate-y-0"
											leaveTo="opacity-0 translate-y-1"
										>
											<Popover.Panel
												static
												className="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
											>
												<div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
													<div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
														{activities.map((item) => (
															<Link
																key={item.name}
																to={item.to}
																className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
															>
																<item.icon
																	className="flex-shrink-0 h-6 w-6 text-primary"
																	aria-hidden="true"
																/>
																<div className="ml-4">
																	<p className="text-base font-medium text-gray-900">
																		{item.name}
																	</p>
																	<p className="mt-1 text-sm text-gray-500">
																		{item.description}
																	</p>
																</div>
															</Link>
														))}
													</div>
													<div className="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
														{callsToAction.map((item) => (
															<div key={item.name} className="flow-root">
																<Link
																	to={item.to}
																	className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100"
																>
																	<item.icon
																		className="flex-shrink-0 h-6 w-6 text-gray-400"
																		aria-hidden="true"
																	/>
																	<span className="ml-3">{item.name}</span>
																</Link>
															</div>
														))}
													</div>
												</div>
											</Popover.Panel>
										</Transition>
									</>
								)}
							</Popover> */}

							{/* <Link
									to="/reviews"
									className="text-base font-medium text-gray-600 hover:text-gray-900"
								>
									Reviews
								</Link>
								<Link
									to="/blog"
									className="text-base font-medium text-gray-600 hover:text-gray-900"
								>
									Blog
								</Link> */}
							{/* <Link
								to="/what-to-expect"
								className="text-base font-medium text-gray-600 hover:text-gray-900"
							>
								What to Expect
							</Link> */}
							{/* <a
								className="text-base font-medium text-gray-600 hover:text-gray-900"
								href="https://shop.artofbicycletrips.in/"
								target="_blank"
							>
								Shop
							</a> */}

							{/* <Link
								to="/blog"
								className="text-base font-medium text-gray-600 hover:text-gray-900"
							>
								Blog
							</Link> */}
							{/* <Link
								to="/private"
								className="text-base font-medium text-gray-600 hover:text-gray-900"
							>
								Design Trip
							</Link> */}
							<Link
								to="/about-us/our-company"
								className="text-base font-medium text-gray-600 hover:text-gray-900"
							>
								About Us
							</Link>
							{/* <Popover className="relative">
								{({ open }) => (
									<>
										<Popover.Button
											className={classNames(
												open ? "text-gray-900" : "text-gray-600",
												"group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
											)}
										>
											<span>More</span>
											<ChevronDownIcon
												className={classNames(
													open ? "text-gray-600" : "text-gray-400",
													"ml-2 h-5 w-5 group-hover:text-gray-500"
												)}
												aria-hidden="true"
											/>
										</Popover.Button>

										<Transition
											show={open}
											as={Fragment}
											enter="transition ease-out duration-200"
											enterFrom="opacity-0 translate-y-1"
											enterTo="opacity-100 translate-y-0"
											leave="transition ease-in duration-150"
											leaveFrom="opacity-100 translate-y-0"
											leaveTo="opacity-0 translate-y-1"
										>
											<Popover.Panel
												static
												className="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
											>
												<div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
													<div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
														{more.map((item) => (
															<Link
																key={item.name}
																to={item.to}
																className="-m-3 p-3 flex items-start rounded-lg hover:bg-secondary"
															>
																<div className="ml-4">
																	<p className="text-base font-medium text-primary">
																		{item.name}
																	</p>
																	<p className="mt-1 text-sm text-gray-600">
																		{item.description}
																	</p>
																</div>
															</Link>
														))}
													</div>
												</div>
											</Popover.Panel>
										</Transition>
									</>
								)}
							</Popover> */}

							{/* <Popover className="relative">
									{({ open }) => (
										<>
											<Popover.Button
												className={classNames(
													open ? "text-gray-900" : "text-gray-600",
													"group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
												)}
											>
												<span>About</span>
												<ChevronDownIcon
													className={classNames(
														open ? "text-gray-600" : "text-gray-400",
														"ml-2 h-5 w-5 group-hover:text-gray-500"
													)}
													aria-hidden="true"
												/>
											</Popover.Button>

											<Transition
												show={open}
												as={Fragment}
												enter="transition ease-out duration-200"
												enterFrom="opacity-0 translate-y-1"
												enterTo="opacity-100 translate-y-0"
												leave="transition ease-in duration-150"
												leaveFrom="opacity-100 translate-y-0"
												leaveTo="opacity-0 translate-y-1"
											>
												<Popover.Panel
													static
													className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-xs sm:px-0"
												>
													<div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
														<div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
															{resources.map((item) => (
																<Link
																	key={item.name}
																	to={item.to}
																	className="-m-3 p-3 flex items-start rounded-lg hover:bg-secondary"
																>
																	
																	<div className="ml-4">
																		<p className="text-sm text-gray-600 hover:text-gray-900">
																			{item.name}
																		</p>
																		
																	</div>
																</Link>
															))}
														</div>
														
													</div>
												</Popover.Panel>
											</Transition>
										</>
									)}
								</Popover> */}
						</Popover.Group>
					</div>

					<Transition
						show={open}
						as={Fragment}
						enter="duration-200 ease-out"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="duration-100 ease-in"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						<Popover.Panel
							focus
							static
							className="absolute z-30 top-0 inset-x-0 p-0 transition transform origin-top-right lg:hidden"
						>
							<div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
								<div className="pt-5 pb-6 px-5">
									<div className="flex items-center justify-between">
										<div>
											{/* <img
												className="h-8 w-auto"
												src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
												alt="Workflow"
											/> */}
										</div>
										<div className="-mr-2">
											<Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary">
												<span className="sr-only">Close menu</span>
												<XIcon className="h-6 w-6" aria-hidden="true" />
											</Popover.Button>
										</div>
									</div>
									{/* <div className="text-right">
										<Location />
									</div> */}
									<div>
										{/* <Link
											to="/upcoming-rides"
											className="w-full flex items-center justify-center mb-4 px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-primary hover:bg-primary"
										>
											Upcoming Trips
										</Link> */}
										<a
											href="https://wa.me/919810645938"
											target="_blank"
											rel="noopener  noreferrer nofollow"
											className="bg-whatsapp w-full flex justify-center font-medium py-2 px-4 border border-gray-800 rounded-md shadow-sm text-base text-gray-800"
										>
											WhatsApp Us
										</a>
										{/* <Link
											to="/contact-us"
											className="w-full flex items-center justify-center px-4 py-2 border border-primary rounded-md shadow-sm text-base font-medium text-primary"
										>
											Contact Us
										</Link> */}
										{/* <p className="mt-6 text-center text-base font-medium text-gray-500">
											Existing customer?{" "}
											<Link to="/private" className="text-primary hover:text-primary">
												Design Trip
											</Link>
										</p> */}
									</div>
									<div className="mt-6">
										<nav className="grid grid-cols-2 gap-y-8 gap-x-12">
											<div className="">
												<p className="mb-4 text-base font-medium text-primary">INDIA</p>
												{india.map((item) => (
													<Link
														key={item.name}
														to={item.to}
														className="-m-3 p-3 flex items-start rounded-md hover:bg-secondary"
													>
														<div className="">
															<p className="text-sm font-medium">{item.name}</p>
															{/* <p className="mt-1 text-sm text-gray-500">
																			{item.description}
																		</p> */}
														</div>
													</Link>
												))}
											</div>
											<div className="">
												<p className="mb-4 text-base font-medium text-primary">ASIA</p>
												{asia.map((item) => (
													<Link
														key={item.name}
														to={item.to}
														className="-m-3 p-3 flex items-start rounded-md hover:bg-secondary"
													>
														<div className="">
															<p className="text-sm font-medium">{item.name}</p>
															{/* <p className="mt-1 text-sm text-gray-500">
																			{item.description}
																		</p> */}
														</div>
													</Link>
												))}
											</div>
										</nav>
									</div>
								</div>
								{/* <div className="py-6 px-5 space-y-6">
									<p className="mb-4 text-base font-medium uppercase text-primary">
										Weekend Retreats
									</p>
									<div className="grid grid-cols-2 gap-y-4 gap-x-8">
										{day.map((item) => (
											<Link
												key={item.name}
												to={item.to}
												className="text-sm font-medium text-gray-900 hover:text-gray-700"
											>
												{item.name}
											</Link>
										))}
									</div>
								</div> */}
								<div className="py-6 px-5 space-y-6">
									<p className="mb-4 text-base font-medium uppercase text-primary">
										Activities
									</p>
									<div className="grid grid-cols-2 gap-y-4 gap-x-8">
										{activities.map((item) => (
											<Link
												key={item.name}
												to={item.to}
												className="text-sm font-medium text-gray-900 hover:text-gray-700"
											>
												{item.name}
											</Link>
										))}
									</div>
								</div>
								{/* <div className="py-6 px-5 space-y-6">
									<p className="mb-4 text-base font-medium uppercase text-primary">
										Cities
									</p>
									<div className="grid grid-cols-2 gap-y-4 gap-x-8">
										{city.map((item) => (
											<Link
												key={item.name}
												to={item.to}
												className="text-sm font-medium text-gray-900 hover:text-gray-700"
											>
												{item.name}
											</Link>
										))}
									</div>
								</div> */}

								{/* <div className="py-6 px-5 space-y-6">
									<p className="mb-4 text-base font-medium uppercase text-primary">
										Speciality Trips
									</p>
									<div className="grid grid-cols-2 gap-y-4 gap-x-8">
										{specialities.map((item) => (
											<Link
												key={item.name}
												to={item.to}
												className="text-sm font-medium text-gray-900 hover:text-gray-700"
											>
												{item.name}
											</Link>
										))}
									</div>
								</div> */}
								<div className="py-6 px-5 space-y-6">
									<div className="grid grid-cols-2 gap-y-4 gap-x-8">
										{/* <Link
											to="/reviews"
											className="text-sm font-medium text-gray-900 hover:text-gray-700"
										>
											Reviews
										</Link>

										<Link
											to="/blog"
											className="text-sm font-medium text-gray-900 hover:text-gray-700"
										>
											Blog
										</Link> */}

										{/* <a
											className="text-sm font-medium text-gray-900 hover:text-gray-700"
											href="https://shop.artofbicycletrips.in/"
											target="_blank"
										>
											Shop
										</a> */}
										{/* <Link
											to="/blog"
											className="text-sm font-medium text-gray-900 hover:text-gray-700"
										>
											Blog
										</Link> */}
										<Link
											to="/about-us/our-company"
											className="text-sm font-medium text-gray-900 hover:text-gray-700"
										>
											About Us
										</Link>

										{/* <Link
											to="/what-to-expect"
											className="text-sm font-medium text-gray-900 hover:text-gray-700"
										>
											What to Expect
										</Link> */}
										{/* {resources.map((item) => (
											<Link
												key={item.name}
												to={item.to}
												className="text-sm font-medium text-gray-900 hover:text-gray-700"
											>
												{item.name}
											</Link>
										))} */}
									</div>
									{/* <div>
										<Link
											to="/upcoming-rides"
											className="w-full flex items-center justify-center mb-4 px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-primary hover:bg-primary"
										>
											Upcoming Trips
										</Link>
										<Link
											to="/private"
											className="w-full flex items-center justify-center px-4 py-2 border border-primary rounded-md shadow-sm text-base font-medium text-primary"
										>
											Design Trip
										</Link>
										
									</div> */}
								</div>
							</div>
						</Popover.Panel>
					</Transition>
				</>
			)}
		</Popover>
	);
}
